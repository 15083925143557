<template>
  <v-layout justify-center align-center column style="width:100%; height:90%">
      <!-- <v-img src="@/assets/comingSoon.svg" width="30%" class="mt-10"></v-img> -->
     <!-- <div id="animationWindow">
        </div> -->
        <!-- <div class="lego red">
  <div class="left"></div>
  <div class="right"></div>
  <div class="container-top">
    <div class="top">
      <div class="dot d-n1"></div>
      <div class="dot d-n2"></div>
      <div class="dot d-n3"></div>
      <div class="dot d-n4"></div>
    </div>
  </div>
</div>

<div class="lego blue">
  <div class="left"></div>
  <div class="right"></div>
  <div class="container-top">
    <div class="top">
      <div class="dot d-n1"></div>
      <div class="dot d-n2"></div>
      <div class="dot d-n3"></div>
      <div class="dot d-n4"></div>
    </div>
  </div>
</div>

<div class="lego yellow">
  <div class="left"></div>
  <div class="right"></div>
  <div class="container-top">
    <div class="top">
      <div class="dot d-n1"></div>
      <div class="dot d-n2"></div>
      <div class="dot d-n3"></div>
      <div class="dot d-n4"></div>
    </div>
  </div>
</div> -->
      <h1  style="justify:center; align: center">UNDER CONSTRUCTION</h1> <br>

      <h5>SITE NEARLY READY!</h5>

       <div :style="$vuetify.breakpoint.lgAndUp? 'width:30%; height:15px;align-content:center;justify-content:center;': 'width:50%; height:15px;align-content:center;justify-content:center;'" class="secondary_white mt-5">
        <div style="width:75%; height:15px;align-content:center;justify-content:center;" class="primary progress"></div>
        </div>
        <div :style="$vuetify.breakpoint.lgAndUp? 'width:29%; height:15px;align-content:center;justify-content:center;': 'width:49%; height:15px;align-content:center;justify-content:center;'" class="mt-3">
          <v-layout justify-space-between row>
            <span>0%</span>
            <span>100%</span>
          </v-layout>
        </div><br>

        <h3 style="font-weight:500;font-size:21px;text-align: center;" class="" v-if="!loggedIn">To avail the early bird andvantages, kindly <span span class="primary--text underline button" style="text-decoration: underline" @click="gotoSignup">Sign-Up!</span></h3>
         <!-- <div class="frame">

  <div class="loader">

    <div class="loader-ring"></div>
    
    <div class="rocket-wrapper">
      <div class="trail-wrapper">
        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/61488/trail.png" alt="" class="trail" />
      </div>
      <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/61488/small-rocket.png" alt="" class="rocket" />
    </div>
    
    <div class="clouds-wrapper">
      
      <svg class="clouds" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 90.83">
        <defs>
          <clipPath id="clip-path" transform="translate(1.75)">
            <circle cx="42.5" cy="42.5" r="42.5" fill="none" />
          </clipPath>
          <filter id="goo" color-interpolation-filters="sRGB">
            <feGaussianBlur in="SourceGraphic" stdDeviation="6" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -11" result="goo" />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
          <filter id="blurMe">
            <feGaussianBlur in="SourceGraphic" stdDeviation="0.9" />
          </filter>
        </defs>
        <g clip-path="url(#clip-path)" fill="#eef2f3" filter="url(#goo)">
          <g class="clouds-wrapper" filter="url(#blurMe)">
            <ellipse class="cloud first" cx="40" cy="61.83" rx="7" ry="7" />
            <ellipse class="cloud second" cx="81" cy="68.83" rx="8" ry="8" />
            <ellipse class="cloud third" cx="6" cy="63.83" rx="6" ry="6"/>
            <ellipse class="cloud forth" cx="15" cy="70.83" rx="11" ry="11"  />
            <ellipse class="cloud fifth" cx="65" cy="74.83" rx="11" ry="11"  />
            <ellipse class="cloud sixth" cx="48" cy="71.83" rx="14" ry="14" />
            <ellipse class="cloud seventh" cx="34" cy="75.83" rx="16" ry="16" />
          </g>
        </g>
      </svg>
      
    </div>

  </div>

</div> -->

  </v-layout>
</template>

<script>
//import gsap from "gsap";
//import TimelineLite from 'gsap';




//import lottie from "lottie-vuejs"; // import lottie-vuejs


export default {
    

  data: () => ({
   

  }),

  created: function(){
//     var animData = {
// 		wrapper: document.querySelector('#animationWindow'),
// 		animType: 'svg',
// 		loop: true,
// 		prerender: true,
// 		autoplay: true,
// 		path: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/35984/LEGO_loader.json'
// 	};
// 	var anim = lottie.loadAnimation(animData);
// anim.setSpeed(3.4);

// var tl = new TimelineMax({repeat: -1, repeatDelay: 0});

// tl.from('.cloud', 1, {alpha: 0})
//   .to('.rocket-wrapper', 3, { y: -400, ease:gsap.Expo.easeIn })
//   .to('.cloud', 3, { attr:{ cy: 185}, ease:gsap.Expo.easeIn }, "-=3")
//   .set('.cloud', {clearProps:"all"})
//   .set('.rocket-wrapper', { y: 450 })
//   .to('.rocket-wrapper', 4, { y:0, ease:gsap.Elastic.easeOut.config(0.5, 0.4) })
//   .to('.trail-wrapper', 2.5, { scaleX:0.5, scaleY:0, alpha:0, ease:gsap.Expo.easeOut }, "-=2.0")




    

  },

  methods: {

  gotoSignup: function(){
    this.$router.push({ name : 'signup'})
  },

  
  },

  computed: {

    loggedIn: function(){
      return this.$store.state.auth.isLoggedIn;
    }
  },

  

}
</script>

<style>
/* $red: #F44336;
$blue: #1E88E5;
$yellow: #FDD835; */

/* :root {
  --blue: #1E88E5;
  --red: #F44336;
  --yellow: #FDD835;
  --shadow-prop: 5px 5px 0px;
  
}

/* $shadow-prop: 5px 5px 0px; */

/* body {
  margin: 0;
  perspective: 1000px;
}

.red.lego {
  animation-delay: 0s;
}

.red.lego.left {
    background: lighten(var(--red), 5%);
  }
.red.lego.right {
    background: darken(var(--red), 5%);
  }
.red.lego.container-top > .top {
    background: var(--red);
}

.red.lego.container-top > .top.dot {
      background: lighten(var(--red), 5%);
      filter: drop-shadow(var(--shadow-prop) darken(var(--red), 10%));
    }

.blue.lego {
  animation-delay: 1.4s;
  
}

.blue.lego.left {
    background: lighten(var(--blue), 5%);
  }
.blue.lego.right {
    background: darken(var(--blue), 5%);
  }
.blue.lego.container-top > .top {
    background: var(--blue);
}

.blue.lego.container-top > .top.dot {
      background: lighten(var(--blue), 5%);
      filter: drop-shadow(var(--shadow-prop) darken(var(--blue), 10%));
    }

.yellow.lego {
  animation-delay: 2.8s;
  
}

.yellow.lego.left {
    background: lighten(var(--yellow), 5%);
  }
 .yellow.lego.right {
    background: darken(var(--yellow), 5%);
  }
  .yellow.lego.container-top > .top {
    background: var(--yellow);
}

.yellow.lego.container-top > .top.dot {
      background: lighten(var(--yellow), 5%);
      filter: drop-shadow(var(--shadow-prop) darken(var(--yellow), 10%));
    }

.lego {
  width: 150px;
  height: 150px;
  position: fixed;
  left: calc(50vw - 75px);
  top: calc(50vh - 100px);
  animation: loading 4.2s infinite ease;
  opacity: 0;
  
}

.lego.left {
    width: 102px;
    height: 35px;
    position: absolute;
    transform: rotateY(60deg) rotate(10deg) rotateX(6deg);
    bottom: 15px;
    border-radius: 5px 0 5px 5px;
  }
 .lego.right {
    width: 102px;
    height: 35px;
    position: absolute;
    transform: rotateY(-60deg) rotate(-10deg) rotateX(5deg);
    bottom: 15px;
    right: 0;
    border-radius: 0 5px 5px 5px;
  }
 .lego.container-top {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 75px;
    height: 75px;
    transform: rotateZ(45deg);
    bottom: 16px;
    
  }

  .lego.container-top.top {
      width: 68px;
      height: 69px;
      position: absolute;
      transform: rotateY(42deg) rotateZ(-17deg) rotateX(-43deg);
      border-radius: 5px 0 0 0;
      
    }

    .lego.container-top.top.dot {
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        
      }

      .dot.d-n1 {left: 7px; top: 7px;}
        .lego.container-top.top.dot.d-n2 {right: 10px; top: 7px;}
        .lego.container-top.top.dot.d-n3 {right: 10px; bottom: 10px;}
        .lego.container-top.top.dot.d-n4 {left: 7px; bottom: 10px;}

@-webkit-keyframes loading {
  0% {transform: translate(0,-50px); opacity: 0; z-index: 10;}
  10% {opacity: 1;}
  40% {transform: translate(0,0); z-index: 1;}
  75% {opacity: 1;}
  100% {transform: translate(0,100px); opacity: 0;}
}

@-moz-keyframes loading {
  0% {transform: translate(0,-50px); opacity: 0; z-index: 10;}
  40% {transform: translate(0,0); opacity: 1; z-index: 1;}
  75% {opacity: 1;}
  100% {transform: translate(0,100px); opacity: 0;}
}  */

.progress {
    background-color: #e4c465;
    animation: progressBar 1s ease-in-out infinite;
    animation-fill-mode: forwards; 
}

@keyframes progressBar {
  0% { width: 74%; }
  100% { width: 75%; }
}

.button{
  cursor: pointer;
}

/* html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
}

body {
  position: relative;
  background-color: #08222e;
}

.frame {
  position: absolute;
  left: calc(50% - 187px);
  top: calc(50% - 333px);
  width: 375px;
  height: 667px;
  background-color: #08222e;
  background-image: radial-gradient( 50% 50%, circle, rgb(2,141,196) 0%, rgb(8,34,46) 100%);
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 2px 3.464px 55.3px 14.7px rgba(0, 0, 0, 0.26);
}

.loader {
  position: absolute;
  width: 105px;
  left: calc(50% - 52px);
  top: calc(50% - 52px);
  animation: fadeIn 2s;
}

.loader-ring {
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/61488/loader-stars.jpg') no-repeat center center;
  background-size: 95px 95px;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  border: 10px solid #d81f44;
}

.rocket-wrapper{
  position: absolute;
  left: 34px;
  top: -30px;
  height: 200px;
}

.rocket {
  width: 37px;
  height: auto;
}

.trail-wrapper {
  position: absolute;
  top: 78px;
  left: 13px;
  width: 12px;
  height: 44px;
  transform: none;
  transform-origin: top center;
  z-index: -1;
}

.trail {
  width: 12px;
  height: auto;
  transform: translateZ(0);
  animation: jet 0.02s ease alternate infinite;
  transform-origin: top center;
}

.clouds-wrapper {
  position: absolute;
  top: 13px;
    left: 10px;
  width: 88px;
  height: 85px;
}

.clouds {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
    width: 92px;
}

.cloud {
  animation: kaboom 0.4s ease alternate infinite;
  transform-origin: center center;
  opacity: 0.9;
  
}

.first .third .fifth .seventh {
    animation: kaboom 0.3s ease alternate infinite;
  }
.first {
      animation-delay: 0.1s;
  }
.second {
      animation-delay: 0.2s;
  }
.third {
    animation-delay: 0.3s;
  }
.forth {
    animation-delay: 0.4s;
  }
.fifth {
    animation-delay: 0.5s;
  }
.sixth {
    animation-delay: 0.6s;
  }
.seventh {
    animation-delay: 0.7s;
  }

@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

@keyframes kaboom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes jet {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.2);
  }
} */
</style>